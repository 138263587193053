<template>
    <div class="ubicacion">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider></v-tabs-slider>
                <v-tab>
                    País
                </v-tab>
                <v-tab>
                    Departamento
                </v-tab>
                <v-tab>
                    Municipio
                </v-tab>
            </v-tabs>
            <v-container>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card>
                            <v-dialog v-model="modalPais" max-width="500px" persistent>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-card-title>
                                            <span class="text-h5">{{ tituloPais }}</span>
                                        </v-card-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="frmPais" v-model="validoPais" lazy-validation>
                                                <v-text-field v-model="editPais.nombre" :rules="reglaNombrePais"
                                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable
                                                    required>
                                                </v-text-field>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="error" class="mr-4" @click="cancelarPais">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn :disabled="!validoPais" color="success" class="mr-4"
                                                        @click="guardarPais">
                                                        Guardar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="modalEliminarPais" max-width="550px">
                                <v-card>
                                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                        <v-icon x-large left color="error">
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        ¿Estas seguro que deceas eliminar este campo?
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cerrarEliminarPais">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="confirmarElimPais">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- tabla -->
                            <tabla :header="headersPais" :body="paises" tituloTabla="PAISES" :accionAgregar="true"
                                :exportar="true" @recargar="listarPais" @agregar="agregarPais" @actualizar="editarPais"
                                @eliminar="eliminarPais" />
                            <!-- tabla fin -->
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card>
                            <v-dialog v-model="modalDepartam" max-width="500px" persistent>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-card-title>
                                            <span class="text-h5">{{ tituloDepartam }}</span>
                                        </v-card-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="frmDepartam" v-model="validoDepartam" lazy-validation>
                                                <v-text-field v-model="editDepartam.nombre" :rules="reglaNombreDepartam"
                                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable
                                                    required>
                                                </v-text-field>
                                                <v-autocomplete v-model="departamPais" :rules="reglaSlcPais"
                                                    :items="paises" item-text="nombre" item-value="Uid"
                                                    prepend-inner-icon="mdi-map" label="País" persistent-hint
                                                    return-object outlined clearable required></v-autocomplete>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="error" class="mr-4" @click="cancelarDepartam">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn :disabled="!validoDepartam" color="success" class="mr-4"
                                                        @click="guardarDepartam">
                                                        Guardar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="modalEliminarDepartam" max-width="550px">
                                <v-card>
                                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                        <v-icon x-large left color="error">
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        ¿Estas seguro que deceas eliminar este campo?
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cerrarEliminarDepartam">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="confirmarElimDepartam">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- tabla -->
                            <tabla :header="headersDepartam" :body="departamentos" tituloTabla="DEPARTAMENTOS"
                                :accionAgregar="true" :exportar="true" @recargar="listarDepartam"
                                @agregar="agregarDepartam" @actualizar="editarDepartam" @eliminar="eliminarDepartam" />
                            <!-- tabla fin -->
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card>
                            <v-dialog v-model="modalMunicipio" max-width="500px" persistent>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-card-title>
                                            <span class="text-h5">{{ tituloMunicipio }}</span>
                                        </v-card-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="frmMunicipio" v-model="validoMunicipio" lazy-validation>
                                                <v-text-field v-model="editMunicipio.nombre"
                                                    :rules="reglaNombreMunicipio" prepend-inner-icon="mdi-format-text"
                                                    label="Name" outlined clearable required>
                                                </v-text-field>
                                                <v-autocomplete v-model="municipioDepartam" :rules="reglaSlcDepartam"
                                                    :items="departamentos" item-text="nombre" item-value="Uid"
                                                    prepend-inner-icon="mdi-city" label="Departamento" persistent-hint
                                                    return-object outlined clearable required>
                                                </v-autocomplete>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="error" class="mr-4" @click="cancelarMunicipio">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn :disabled="!validoMunicipio" color="success" class="mr-4"
                                                        @click="guardarMunicipio">
                                                        Guardar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="modalEliminarMunicipio" max-width="550px">
                                <v-card>
                                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                        <v-icon x-large left color="error">
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        ¿Estas seguro que deceas eliminar este campo?
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cerrarEliminarMunicipio">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="confirmarElimMunicipio">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- tabla -->
                            <tabla :header="headersMunicipio" :body="municipios" tituloTabla="MUNICIPIOS"
                                :accionAgregar="true" :exportar="true" @recargar="listarMunicipio"
                                @agregar="agregarMunicipio" @actualizar="editarMunicipio"
                                @eliminar="eliminarMunicipio" />
                            <!-- tabla fin -->
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
        </v-card>
    </div>
</template>
<style>

</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../components/view/Tabla";
    export default {
        name: "ubicacion",
        components: {
            tabla,
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables pais 
            UidPais: null,
            buscarPais: '',
            validoPais: true,
            modalPais: false,
            modalEliminarPais: false,
            headersPais: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / País',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            paises: [],
            reglaNombrePais: [
                v => !!v || 'El nombre del pais es obligatorio.'
            ],
            editedPaisIndex: -1,
            editPais: {
                nombre: ''
            },
            defaultPais: {
                nombre: ''
            },
            // fin variables pais

            //variables departamento
            UidDepartam: null,
            buscarDepartam: '',
            validoDepartam: true,
            modalDepartam: false,
            modalEliminarDepartam: false,
            headersDepartam: [{
                    text: '#',
                    value: 'numero'
                }, {
                    text: 'Nombre / Departamento',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            departamentos: [],
            reglaNombreDepartam: [
                v => !!v || 'El nombre del departamento es obligatorio.'
            ],
            reglaSlcPais: [v => !!v || 'El pais es obligatorio'],
            editedDepartamIndex: -1,
            departamPais: null,
            editDepartam: {
                nombre: '',
            },
            defaultDepartam: {
                nombre: '',
            },
            // fin variables departamento

            //variables municipio
            UidMunicipio: null,
            buscarMunicipio: '',
            validoMunicipio: true,
            modalMunicipio: false,
            modalEliminarMunicipio: false,
            headersMunicipio: [{
                    text: '#',
                    value: 'numero'
                }, {
                    text: 'Nombre / Municipio',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            municipios: [],
            reglaNombreMunicipio: [
                v => !!v || 'El nombre del municipio es obligatorio.'
            ],
            reglaSlcDepartam: [v => !!v || 'El departamento es obligatorio'],
            editedMunicipioIndex: -1,
            municipioDepartam: null,
            editMunicipio: {
                nombre: '',
            },
            defaultMunicipio: {
                nombre: '',
            },
            // fin variables municipio
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal pais 
            tituloPais() {
                return this.editedPaisIndex === -1 ? 'Nuevo País' : 'Editar País'
            },
            // titulo modal departamento 
            tituloDepartam() {
                return this.editedDepartamIndex === -1 ? 'Nuevo Departamento' : 'Editar Departamento'
            },
            // titulo modal municipio 
            tituloMunicipio() {
                return this.editedMunicipioIndex === -1 ? 'Nuevo Municipio' : 'Editar Municipio'
            },
        },

        watch: {
            // modal pais
            modalPais(val) {
                val || this.cancelarPais()
            },
            modalEliminarPais(val) {
                val || this.cerrarEliminarPais()
            },
            // fin modal pais
            // modal departamento
            modalDepartam(val) {
                val || this.cancelarDepartam()
            },
            modalEliminarDepartam(val) {
                val || this.cerrarEliminarDepartam()
            },
            // fin modal departamento
            // modal municipio
            modalMunicipio(val) {
                val || this.cancelarMunicipio()
            },
            modalEliminarMunicipio(val) {
                val || this.cerrarEliminarMunicipio()
            },
            // fin modal municipio
        },

        created() {
            // listar paises
            this.listarPais()
            // listar departamentos
            this.listarDepartam()
            // listar departamentos
            this.listarMunicipio()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD paises 
            async listarPais() {
                try {
                    await fetch(`${this.url_api}/pais`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.paises = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            agregarPais() {
                this.modalPais = true
            },

            editarPais(pais) {
                this.editedPaisIndex = this.paises.indexOf(pais)
                this.editPais = Object.assign({}, pais)
                this.modalPais = true
                this.UidPais = pais.Uid;
            },

            eliminarPais(pais) {
                this.editedPaisIndex = this.paises.indexOf(pais)
                this.editPais = Object.assign({}, pais)
                this.modalEliminarPais = true
                this.UidPais = pais.Uid;
            },

            async confirmarElimPais() {
                try {
                    await fetch(`${this.url_api}/pais/${this.UidPais}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.paises.splice(this.editedPaisIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Rerror al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarPais();
            },

            cancelarPais() {
                this.$refs.frmPais.reset()
                this.$refs.frmPais.resetValidation()
                this.modalPais = false
                this.$nextTick(() => {
                    this.editPais = Object.assign({}, this.defaultPais)
                    this.editedPaisIndex = -1
                })
            },

            cerrarEliminarPais() {
                this.modalEliminarPais = false
                this.$nextTick(() => {
                    this.editPais = Object.assign({}, this.defaultPais)
                    this.editedPaisIndex = -1
                })
            },

            async guardarPais() {
                if (this.$refs.frmPais.validate()) {
                    if (this.editedPaisIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/pais/${this.UidPais}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "nombrePais": this.editPais.nombre
                                    }),
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.paises[this.editedPaisIndex], this.editPais);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidPais = null
                    } else {
                        try {
                            await fetch(`${this.url_api}/pais`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "nombrePais": this.editPais.nombre
                                    }),
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarPais();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarPais()
                }
            },
            // fin CRUD paises

            // CRUD departamentos 
            async listarDepartam() {
                try {
                    await fetch(`${this.url_api}/departamento`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.departamentos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error)
                }
            },

            agregarDepartam() {
                this.modalDepartam = true
            },

            editarDepartam(departamento) {
                let me = this;
                this.paises.forEach(element => {
                    if (element.Uid == departamento.pais) {
                        me.departamPais = {
                            Uid: element.Uid,
                            nombre: element.nombre
                        };
                    }
                });

                this.editedDepartamIndex = this.departamentos.indexOf(departamento)
                this.editDepartam = Object.assign({}, departamento)
                this.modalDepartam = true
                this.UidDepartam = departamento.Uid;

            },

            eliminarDepartam(departamento) {
                this.editedDepartamIndex = this.departamentos.indexOf(departamento)
                this.editDepartam = Object.assign({}, departamento)
                this.modalEliminarDepartam = true
                this.UidDepartam = departamento.Uid;
            },

            async confirmarElimDepartam() {
                try {
                    await fetch(`${this.url_api}/departamento/${this.UidDepartam}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.departamentos.splice(this.editedDepartamIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Rerror al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarDepartam();
            },

            cancelarDepartam() {
                // this.departamPais = {}
                this.$refs.frmDepartam.reset()
                this.$refs.frmDepartam.resetValidation()
                this.modalDepartam = false
                this.$nextTick(() => {
                    this.editDepartam = Object.assign({}, this.defaultDepartam)
                    this.editedDepartamIndex = -1
                })
            },

            cerrarEliminarDepartam() {
                this.modalEliminarDepartam = false
                this.$nextTick(() => {
                    this.editDepartam = Object.assign({}, this.defaultDepartam)
                    this.editedDepartamIndex = -1
                })
            },

            async guardarDepartam() {
                if (this.$refs.frmDepartam.validate()) {
                    if (this.editedDepartamIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/departamento/${this.UidDepartam}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "paisDepartamento": this.departamPais.Uid,
                                        "nombreDepartamento": this.editDepartam.nombre
                                    })
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.departamentos[this.editedDepartamIndex], this
                                            .editDepartam);
                                        this.listarDepartam();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.Uid = null
                    } else {
                        try {
                            await fetch(`${this.url_api}/departamento`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "paisDepartamento": this.departamPais.Uid,
                                        "nombreDepartamento": this.editDepartam.nombre
                                    })
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato guardado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarDepartam();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarDepartam();
                }
            },
            // fin CRUD departamentos

            // CRUD municipio 
            async listarMunicipio() {
                try {
                    await fetch(`${this.url_api}/municipio`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.municipios = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarMunicipio() {
                this.modalMunicipio = true
            },

            editarMunicipio(municipio) {
                let me = this;
                this.departamentos.forEach(element => {
                    if (element.Uid == municipio.departamento) {
                        me.municipioDepartam = {
                            Uid: element.Uid,
                            nombre: element.nombre
                        };
                    }
                });

                this.editedMunicipioIndex = this.municipios.indexOf(municipio)
                this.editMunicipio = Object.assign({}, municipio)
                this.modalMunicipio = true
                this.UidMunicipio = municipio.Uid;

            },

            eliminarMunicipio(municipios) {
                this.editedMunicipioIndex = this.municipios.indexOf(municipios)
                this.editMunicipio = Object.assign({}, municipios)
                this.modalEliminarMunicipio = true
                this.UidMunicipio = municipios.Uid;
            },

            async confirmarElimMunicipio() {
                try {
                    await fetch(`${this.url_api}/municipio/${this.UidMunicipio}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.municipios.splice(this.editedMunicipioIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Rerror al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarMunicipio();
            },

            cancelarMunicipio() {
                this.$refs.frmMunicipio.reset()
                this.$refs.frmMunicipio.resetValidation()
                this.modalMunicipio = false
                this.$nextTick(() => {
                    this.editMunicipio = Object.assign({}, this.defaultMunicipio)
                    this.editedMunicipioIndex = -1
                })
            },

            cerrarEliminarMunicipio() {
                this.modalEliminarMunicipio = false
                this.$nextTick(() => {
                    this.editMunicipio = Object.assign({}, this.defaultMunicipio)
                    this.editedMunicipioIndex = -1
                })
            },

            async guardarMunicipio() {
                if (this.$refs.frmMunicipio.validate()) {
                    if (this.editedMunicipioIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/municipio/${this.UidMunicipio}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "departamentoMunicipio": this.municipioDepartam.Uid,
                                        "nombreMunicipio": this.editMunicipio.nombre
                                    }),
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.municipios[this.editedMunicipioIndex], this
                                            .editMunicipio);
                                        this.listarMunicipio();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.Uid = null
                    } else {
                        try {
                            await fetch(`${this.url_api}/municipio`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "departamentoMunicipio": this.municipioDepartam.Uid,
                                        "nombreMunicipio": this.editMunicipio.nombre
                                    }),
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato guardado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarMunicipio();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }

                    this.cancelarMunicipio()
                }
            }
            // fin CRUD municipio
        },
    }
</script>